@import "../vars";

.page-footer {

  svg {
    &.is-inst-icon {
      fill: $inst-icon-color;
    }
  }
}
