$orange-color: #ff5a3c;
$border-orange-color: #ff5a3c;
$bg-orange-color-hover: #fb412a;

$fb-icon-color: #3273dc;
$vk-icon-color: #3298dc;
$tlg-icon-color: #3298dc;
$whsp-icon-color: #48c774;
$vbr-icon-color: #ad29f1;
$inst-icon-color: #f14668;

$eml-icon-color: #808080;
