@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "vars";
.landing-page {
    svg {
        fill: $orange-color;
    }
}

.solves {
    text-align: center;
    .saleIcon {
        color: $orange-color;
    }
}

.lead {
  font-size: 1.35rem;
}

#landing-hero-slider {
    img {
        @include media-breakpoint-up(xs) {
            height: 580px;
        }
        @include media-breakpoint-up(md) {
            height: 620px;
        }
        object-fit: cover;
    }
}

.reasons svg {
    margin-bottom: 1.5rem;
}

.about .badge {
    background-color: $orange-color;
}

.how-i-working {
    svg {
        width: 4rem;
        height: 4rem;
        margin-bottom: 1rem;
    }
}

.contacts {
    .social-links {
        svg {
            width: 3rem;
            &.is-tlg-icon {
                fill: $tlg-icon-color;
            }
            &.is-vbr-icon {
                fill: $vbr-icon-color;
            }
            &.is-whsp-icon {
                fill: $whsp-icon-color;
            }
            &.is-inst-icon {
                fill: $inst-icon-color;
            }
            &.is-eml-icon {
                fill: $eml-icon-color;
            }
        }
    }
}