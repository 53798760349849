.portfolio-tags {
  margin: 10px 0;
}
.portfolio-element {
  height: 100px;
}
.portfolio-tags {
  display: flex;
  gap: 10px;
}

.itemPortfolio {
  width: 30%;
  object-fit: contain
}

.columnPortfolio {

  .item__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
  }
}

.tegButton {
  border-radius: 5px;
  
  &.active {
    border: 2px solid #ff5a3c;
  }
}

 @media screen and (max-width: 768px) {
  .portfolio-tags {
    justify-content: center;
    flex-wrap: wrap;
  }
  .item__wrapper  {
    > img {
      min-width: 200px;
    }
  }
}