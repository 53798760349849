@import "vars";

@import "./Components/Header";
@import "./Components/ButtonUp";
@import "./Components/Footer";

.btn {

  &-red-orange {
    color: #fff;
    background-color: $orange-color;
    border-color: $border-orange-color;

    &:hover {
      color: #fff;
      background-color: $bg-orange-color-hover;
    }
  }

  &-outline {

    &-red-orange {
      background-color: #fff;

      &:hover {

        svg path {
          fill: #fff;
        }
      }
    }

    &-red-orange {
      color: $orange-color;
      border-color: $border-orange-color;

      &:hover {
        color: #fff;
        background-color: $bg-orange-color-hover;
      }
    }
  }
}

.text {

  &-red-orange {
    color: $border-orange-color;
  }
}

.is-fb-icon { color: $fb-icon-color; }

.is-vk-icon { color: $vk-icon-color; }

.is-tlg-icon { color: $tlg-icon-color; }

.is-whsp-icon { color: $whsp-icon-color; }

.is-vbr-icon { color: $vbr-icon-color; }

.is-inst-icon { color: $inst-icon-color; }

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bi {
  vertical-align: -.125em;
}
