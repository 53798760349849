@import "../_vars";

.nav-item {
  text-align: center;

  .nav-link {

    &:hover {
      color: $orange-color !important;
    }

    &.active {
      font-weight: bold;
    }
  }
}

.navbar-nav {
  gap: 2px;
}

.phone-number {
  font-size: 22px;
  color: $orange-color;
  font-weight: 700;
}

.social-header {

  a {
    span {
      font-size: .8rem;
    }
  }

  svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;

    &.is-insta-icon {
      fill: $inst-icon-color;
    }

    &.is-phone-icon {
      fill: $orange-color;
    }

    &.is-tlg-icon {
      fill: $tlg-icon-color;
    }

    &.is-vbr-icon {
      fill: $vbr-icon-color;
    }

    &.is-whsp-icon {
      fill: $whsp-icon-color;
    }
  }
}
